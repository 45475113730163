<template>
    <div>
    </div>
</template>
<script>
import '@libs/leaflet_plug/measure-path/leaflet-measure-path.css';
import '@libs/leaflet_plug/measure-path/leaflet-measure-path.js';
'use strict';
export default {
    name: 'measure-area',
    props: {
        basicMap: {
            type: Object,
            default: ''
        },
        currentTool: String
    },
    data() {
        return {
            areaMeasure: null
        };
    },
    computed: {
    },
    mounted() {
        this.control_area();
    },
    methods: {
        control_area() {
            let _this = this;
            let myMap = this.basicMap;
            let areaCursor = myMap.getContainer();
            $(areaCursor).addClass('ruler-map');
            let lat; let
                lng;
            let myGroup;
            let layers1 = [];
            myGroup = L.layerGroup(layers1);
            myMap.addLayer(myGroup);
            if ($('.measure-distance').hasClass('icon-active')) {
                $('.measure-distance')[0].click();
            }
            if (this.areaMeasure) {
                this.areaMeasure.destory();
            }
            if (this.currentTool) {
                var areaMeasure = {
                    points: [],
                    color: '#FF0080',
                    layers: L.layerGroup(),
                    polygon: null,
                    init: function() {
                        areaMeasure.points = [];
                        areaMeasure.polygon = null;
                        myMap.on('click', areaMeasure.click).on('dblclick', areaMeasure.dblclick);
                    },
                    click: function(e) {
                        myMap.doubleClickZoom.disable();
                        // 添加点信息
                        areaMeasure.points.push(e.latlng);
                        // 添加面
                        myMap.on('mousemove', areaMeasure.mousemove);
                    },
                    mousemove: function(e) {
                        areaMeasure.points.push(e.latlng);
                        if (areaMeasure.polygon) myMap.removeLayer(areaMeasure.polygon);
                        areaMeasure.polygon = L.polygon(areaMeasure.points, { showMeasurements: true, color: '#FF0080' });
                        areaMeasure.polygon.addTo(myMap);
                        areaMeasure.polygon.addTo(areaMeasure.layers);
                        areaMeasure.layers.addTo(myMap);
                        areaMeasure.points.pop();
                        lat = e.latlng.lat;
                        lng = e.latlng.lng;
                        let marker = L.divIcon({
                            html:
                                '<div style="width:150px;height:30px;line-height:30px; border:1px solid #fd7f44;text-align:center; background:#fff;">单击确定地点，双击结束</div>',
                            iconAnchor: [-1, 42]
                        });
                        myGroup.clearLayers();
                        let numMarker = L.marker([lat, lng], {
                            icon: marker
                        });
                        myGroup.addLayer(numMarker);
                    },
                    dblclick: function(e) {
                        // 双击结束
                        areaMeasure.polygon.addTo(areaMeasure.layers);
                        let LAT = e.latlng.lat;
                        let LNG = e.latlng.lng;
                        let divIcon = L.divIcon({
                            html: "<div style='width:15px;height:15px;line-height:15px; border-radius:3px;font-size:14px;background:#FF0080;color:#fff;'>×</div>",
                            iconAnchor: [11, -2]
                        });
                        let biaojiMarker = L.marker([LAT, LNG], { icon: divIcon });
                        myMap.addLayer(biaojiMarker);
                        biaojiMarker.on('click', function() {
                            myMap.removeLayer(biaojiMarker);
                            myMap.removeLayer(areaMeasure.polygon);
                        });
                        myMap.on('editable:vertex:drag editable:vertex:deleted', areaMeasure.polygon.updateMeasurements, areaMeasure.polygon);
                        myMap
                            .off('click', areaMeasure.click)
                            .off('mousemove', areaMeasure.mousemove)
                            .off('dblclick', areaMeasure.dblclick);
                        $(areaCursor).removeClass('ruler-map');
                        myGroup.clearLayers();
                        _this.$emit("update:currentTool", '');
                    },
                    destory: function() {
                        // myMap.on('editable:vertex:drag editable:vertex:deleted', areaMeasure.polygon.updateMeasurements, areaMeasure.polygon);
                        myMap
                            .off('click', areaMeasure.click)
                            .off('mousemove', areaMeasure.mousemove)
                            .off('dblclick', areaMeasure.dblclick);
                        $(areaCursor).removeClass('ruler-map');
                        myGroup.clearLayers();
                    }
                };
                areaMeasure.init();
                this.areaMeasure = areaMeasure;
            }
        },
    }
};
</script>
<style scoped>
</style>